import React from "react"
import { graphql, Link } from "gatsby"

// import Introduction from "../components/Global/Introduction"
import Main from "../components/Contact/Main"
import ContactForm from "../components/Global/ContactForm"
import Breadcrumb from "../components/Global/Breadcrumb"
import ContactInformation from "../components/Contact/ContactInformation"




import Layout from '../components/layout'


function ContactTemplate(props) {
    let data = props.data.wpgraphql;

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.page.breadcrumbAcf.breadcrumbName,
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : ""}${props.pageContext.currentPage}`
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://karanikolascon.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://karanikolascon.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}
                recentPosts = { data.posts }
                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                projects={props.pageContext.projectsCategories}
                preHeader={data.template.contactInformationTemplate}
            >
                <div>
                    {data.page.breadcrumbAcf.breadcrumbName && (
                        <section>
                            <Breadcrumb list={getBreadcrumb()} image={data.page.breadcrumbAcf.image} h1={data.page.breadcrumbAcf.breadcrumbName} />
                        </section>
                    )}
                    <div className="container m-auto">
                        <div className="w-full">
                            <ContactInformation data={data.template.contactInformationTemplate} />
                        </div>
                        <div className="w-full">
                            <ContactForm
                                form={data.template.contactInformationTemplate.form}
                                responses={data.template.contactInformationTemplate.responseMessages}
                                endPoint={"contactEmail"}
                                language={props.pageContext.language}
                                subjectRequired={true}
                                messageRequired={true}
                            />
                        </div>
                    </div>

                </div>
            </Layout>
        </div>
    )
}

export default ContactTemplate

export const pageQuery = graphql`query GET_CONTACT_PAGE($id: ID!, $themeTemplateUri: ID!) {
  wpgraphql {
    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      breadcrumbAcf {
        breadcrumbName
        image {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        informationTitle
        telephoneNumber
        mobileNumber
        supportEmail
        address {
          addressGoogleLink
          addressLabel
        }
        hoursTitle
        hoursLabel
        form {
          title
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    
  }
}
`
