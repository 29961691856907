import React, { useState, useRef, useEffect } from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import Formsy from 'formsy-react';
import BackgroundImage from 'gatsby-background-image'
import axios from "axios"

import FormsyInput from "./../Formsy/formsyInput"
import FormsyTextArea from "./../Formsy/FormsyTextArea"

import "./ContactForm.css";


function ContactForm(props) {
    const data = props;


    const formRef = useRef(null);


    const [canSubmit, setCanSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailFailed, setEmailFailed] = useState(false);
    const [emailResponse, setEmailResponse] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [showErrorCheckbox, setShowErrorCheckbox] = useState(false);
    const [hasGdpr, setHasGdpr] = useState(false)

    useEffect(() => {
        if (canSubmit) {
            setShowErrorMessage(false)
        }
    }, [canSubmit])

    useEffect(() => {
        if (props.endPoint === "contactEmail") {
            setHasGdpr(true);
        }
    }, [])


    function disableButton() {
        setCanSubmit(false);
    }

    function enableButton() {
        setCanSubmit(true);
    }

    function submit(model) {
        setLoading(true)

        let type = "";

        if (model.fullName) {
            let payload = {
                ...model,
                language: props.language,
            }

            axios.post(`https://admin.karanikolascon.gr/wp-json/myplugin/v1/websiteContactEmail`, payload)
                .then(function (response) {
                    // handle success
                    setEmailFailed(false)
                    setLoading(false)
                    setEmailSent(true)
                    setEmailResponse(response.data)

                })
                .catch(function (error) {
                    // handle error
                    setLoading(false)
                    setEmailFailed(true)
                    setEmailResponse(data.responses.failed)
                })
        }
    }

    function test(e) {
        e.preventDefault();
        console.log(e)
        if (!hasGdpr) {
            setShowErrorCheckbox(true)
        } else {
            setShowErrorMessage(true)
        }

    }

    return (
        <div className={`px-4 pb-12 md:px-0`}>
            <div className={`${emailSent ? "max-w-none px-8 " : "px-4 "}relative`} >
                {loading && (
                    <div className="loader-container">
                        <div className="loader">Loading...</div>
                    </div>
                )}

                {emailFailed && (
                    <p style={{ color: "#b70000", fontSize: "1.2rem" }}>{emailResponse}</p>
                )}

                {!emailSent
                    ?
                    <Formsy
                        onValidSubmit={submit}
                        onValid={enableButton}
                        onInvalid={disableButton}
                        className={loading ? "opacity-25" : "opacity-100"}
                        ref={formRef}
                    >
                        <h2 className="text-4xl">
                            {data.form.title}
                        </h2>

                        <div className="mt-12 flex">

                            <div className="w-1/3">
                                <FormsyInput
                                    name="fullName"
                                    placeholder={data.form.fullName}
                                    classes="mt-4 w-full"
                                    required
                                />
                                <FormsyInput
                                    name="email"
                                    validations={"isEmail"}
                                    validationErrors={{
                                        isEmail: data.form.email.errorMessage,
                                    }}
                                    placeholder={data.form.email.label}
                                    classes="mt-4 w-full "
                                    required
                                />

                                <FormsyInput
                                    name="subject"
                                    placeholder={data.form.subject}
                                    classes="mt-4 w-full "
                                    defaultValue={data.subject}
                                    required
                                />
                            </div>

                            <div className="w-2/3 pl-0 md:pl-8 lg:pl-12">
                                <FormsyTextArea
                                    name="message"
                                    validations={"minLength: 9"}
                                    validationErrors={{
                                        minLength: data.form.message.errorMessage,
                                    }}
                                    placeholder={data.form.message.label}
                                    classes="h-full"
                                    required
                                />
                            </div>


                        </div>

                        <button className={"home-intro-bttn mt-8"}>
                            {data.form.sendButton}
                        </button>

                        {showErrorMessage && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Συμπληρώστε όλα τα απαραίτητα πεδία (*)</p>
                        )}
                        {showErrorCheckbox && (
                            <p className="text-center mt-8" style={{ color: "#b70000", fontSize: "1.2rem", }}>Παρακαλoύμε αποδεχτείτε τους όρους χρήσης</p>
                        )}
                    </Formsy>
                    :
                    <div >
                        <div className="email-response" dangerouslySetInnerHTML={{ __html: emailResponse }} />
                    </div>
                }

            </div>
        </div>
    )
}

export default ContactForm;